<script setup>
import { onMounted, reactive } from "vue";

const props = defineProps({
  cookiesPolicyUrl: {
    type: String,
    required: true,
  },
});

const data = reactive({
  cookieRef: "LR_hacp=true",
  hideBanner: true,
});

onMounted(() => {
  document.addEventListener("scroll", validateCookieStatus, false);
});

function hasAceptedCookiesPolicy() {
  document.cookie = data.cookieRef + "; max-age=31536000; samesite=strict";
  data.hideBanner = true;
}
function validateCookieStatus() {
  data.hideBanner = document.cookie.indexOf(data.cookieRef) >= 0;
  document.removeEventListener("scroll", validateCookieStatus, false);
}
</script>
<template>
  <div class="cookies" v-if="!data.hideBanner">
    <div class="wrap-cookies">
      <div class="title"><span>COOKIES</span></div>
      <p>
       Parce, toca usar cookies para que navegar este sitio sea una chimba. Consulte nuestra <a :href="cookiesPolicyUrl">Política de Cookies</a> (De una o miedo).        
      </p>
      <div class="">
        <button class="button" @click="hasAceptedCookiesPolicy">
          <span>DE UNA</span>
        </button>
      </div>
    </div>
  </div>
</template>