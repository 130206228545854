import { ref, onMounted, onUnmounted } from "vue";

export const useScroll = (trigger) => {
  const y = ref(window.scrollY);
  const isActive = ref(window.scrollY >= trigger);
  let ticking = false;
  let last_known_scroll_position = window.scrollY;
  let last_assigned_scroll_position = window.scrollY;

  const update = () => {
    last_known_scroll_position = window.scrollY;
    if (!ticking) {
      window.requestAnimationFrame(function () {
        y.value = last_known_scroll_position;
        // isActive.value = last_known_scroll_position >= trigger;
        if (
          Math.abs(last_assigned_scroll_position - last_known_scroll_position) >
          trigger
        ) {
          isActive.value = last_known_scroll_position > last_assigned_scroll_position;
          last_assigned_scroll_position = last_known_scroll_position;
        }
        ticking = false;
      });
    }
    ticking = true;
  };

  onMounted(() => window.addEventListener("scroll", update));
  onUnmounted(() => window.removeEventListener("scroll", update));

  return { y, isActive };
};
