import { createApp } from "vue";
import { useScroll } from "../../../global/vue/composables/scroll";
import CookiesBanner from "./components/CookiesBanner.vue";

export const init = (components, plugins) => {
  components.CookiesBanner = CookiesBanner;
  const app = createApp({
    components: components,
    setup: () => {
      const { y, isActive } = useScroll(120);
      return { y, isActive };
    },
  });

  if (plugins) {
    plugins.forEach((plugin) => {
      app.use(plugin);
    });
  }

  const vm = app.mount("#principal-html");

  return {
    app,
    vm,
  };
};
