import { Subject } from "rxjs";
import { processFB } from "./lazy/fb";
import { processIframe } from "./lazy/iframe";
import { processImg } from "./lazy/img";
import { processIG } from "./lazy/instagram";
import { processTwitter } from "./lazy/twitter";
import { processVimeo } from "./lazy/vimeo";
import { processYT } from "./lazy/yt";

const intersections = new Subject();
const intersectionObserver = new IntersectionObserver(
  function (entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        const lazyElement = entry.target;
        if (lazyElement.classList.contains("lazyload")) {
          lazyElement.classList.remove("lazyload");
          intersections.next(lazyElement);
        }
        intersectionObserver.unobserve(lazyElement);
      }
    });
  },
  {
    rootMargin: "0px 0px 256px 0px",
  }
);
const intersectionSubscription = intersections.subscribe({
  next: (el) => {
    if (el.nodeName === "IFRAME") {
      processIframe(el);
    } else if (el.nodeName === "IMG") {
      processImg(el);
    } else if (el.classList.contains("yt-video")) {
      processYT(el);
    } else if (el.classList.contains("vimeo-video")) {
      processVimeo(el);
    } else if (
      el.classList.contains("fb-post") ||
      el.classList.contains("fb-video")
    ) {
      processFB(el);
    } else if (el.classList.contains("tw")) {
      processTwitter(el);
    } else if (el.classList.contains("instagram-media-tmp")) {
      processIG(el);
    }
  },
});

export const initIntersections = () => {
  let lazyElements = [].slice.call(document.querySelectorAll(".lazyload"));
  if (lazyElements.length) {
    lazyElements.forEach(function (lazyObj) {
      intersectionObserver.observe(lazyObj);
    });
  }
};
