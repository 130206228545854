export const processImg = (el) => {
  let newSrc = `${el.dataset.src}?w=${getWidth(el)}`;
  if (window.devicePixelRatio !== 1) {
    newSrc += `&d=${window.devicePixelRatio}`;
  }
  el.src = newSrc;

  el.removeAttribute("data-src");
  if (el.dataset.srcset) {
    el.srcset = el.dataset.srcset;
    el.removeAttribute("data-srcset");
  }
};

const getWidth = (el) => {
  return el.parentNode.clientWidth
    ? el.parentNode.clientWidth
    : getWidth(el.parentNode);
};
