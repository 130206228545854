let vimeoPendingVideos = [];
let vimeoIsInitialized = false;
let vimeoPlayer = null;

export const processVimeo = (el) => {
  if (vimeoIsInitialized) {
    insertVimeoPlayer(el);
  } else {
    if (!vimeoPendingVideos.length) {
      insertVimeoScript();
    }
    vimeoPendingVideos.push(el);
  }
};

const insertVimeoScript = async () => {
  return import("@vimeo/player")
    .then(({ default: Player }) => {
      vimeoIsInitialized = true;
      vimeoPlayer = Player;
      vimeoPendingVideos.forEach(el => {
        insertVimeoPlayer(el);
      });
    })
    .catch((error) => "An error occurred while loading the component");
};

const insertVimeoPlayer = (el) => {
  new vimeoPlayer(el);
};
